@import '@angular/material/theming';

$primary: #0277bd;
$primary-light: #0288d1;
$primary-dark: #01579b;
$accent: #e64a19;
$accent-light: #f4511e;
$accent-dark: #bf360c;
$warn: #c62828;
$warn-light: #d32f2f;
$warn-dark: #b71c1c;