// Custom Theming for Angular Material
@use '@angular/material' as mat;
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$rt-quote-app-primary: mat.define-palette(mat.$light-blue-palette, 800, 700, 900);
$rt-quote-app-accent: mat.define-palette(mat.$deep-orange-palette, 700, 600, 900);

// The warn palette is optional (defaults to red).
$rt-quote-app-warn: mat.define-palette(mat.$red-palette, 800, 700, 900);

// Create the theme object (a Sass map containing all of the palettes).
$rt-quote-app-theme: mat.define-light-theme($rt-quote-app-primary, $rt-quote-app-accent, $rt-quote-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($rt-quote-app-theme);

/* You can add global styles to this file, and also import other style files */

// Global styles.
@import "styles-global-vars.scss";

// Reset styles.
*, *::before, *::after {
  box-sizing: border-box;
}

html, body {
  height: 100%;
  font-size: 10px;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
  background-color: #fff;
}

img { vertical-align: middle; }

a {
  color: #337ab7;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #23527c;
  text-decoration: underline;
}

a:focus {
  outline: 5px auto -webkit-focus-ring-color;
  outline-offset: -2px;
}

[role="button"] {
  cursor: pointer;
}

label {
  font-weight: 700; 
}

// Main layout styles.
.common-container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.common-container.common-container--main {
  min-height: 70vh;
}

@media (min-width: 768px) {
  .common-container {
    width: 750px;
  }
}

@media (min-width: 992px) {
  .common-container {
    width: 970px;
  }
}

@media (min-width: 1200px) {
  .common-container {
    width: 1170px;
  }
}

.grid-main-content {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto auto;
  column-gap: 30px;
  row-gap: 30px;
  grid-template-areas: 
    "main"
    "aside";
}

@media (min-width: 992px) {
  .grid-main-content {
    grid-template-columns: minmax(0, 1fr) 280px;
    grid-template-rows: auto;
    grid-template-areas: 
      "main aside";
  }

  .grid-main-content.full-width {
    grid-template-areas: 
      "main main";
  }
}

.item-main {
  grid-area: main;
}

.item-aside {
  grid-area: aside;
}

// Dialog component styles.
.app-scope,
.mat-dialog-content {
  font: 400 16px/20px Roboto, "Helvetica Neue", sans-serif;
}

.mat-dialog-content h2 {
  font: 500 20px/32px Roboto, "Helvetica Neue", sans-serif;
  margin-bottom: 16px;
}

.mat-dialog-content h3 {
  font: 500 18px/28px Roboto, "Helvetica Neue", sans-serif;
}

// Styles for footer dynamic content.
.ftr__link {
  color: #fff;
}

.ftr__link:link,
.ftr__link:visited {
  color: #fff;
  text-decoration: none;
}

.ftr__link:focus,
.ftr__link:hover,
.ftr__link:active {
  text-decoration: underline;
}

// Common page items.
.common-intro {
  position: relative;
}

.app-scope .common-intro__hdr {
  font-size: 36px;
  line-height: 44px;
  margin-bottom: 1em;
}

.app-scope .common-intro__text {
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 1.5em;
}

.common-char-width-limit {
  max-width: 70ch;
}

// Common form items.
.form-group-container {
  margin-bottom: 30px;
  max-width: 400px;
}

.form-item > .mat-form-field  {
  display: block;
}

// Common list.
.app-scope .common-list {
  margin-bottom: 15px;
}

.app-scope .common-list__item {
  margin-bottom: 10px;
}

.app-scope .common-list__item:last-of-type {
  margin-bottom: 0px;
}

// Standard padding and margins.
.app-scope .common-margin-sm-y {
  margin-top: 15px;
  margin-bottom: 15px;
}

.app-scope .common-margin-sm-x {
  margin-left: 15px;
  margin-right: 15px;
}

.app-scope .common-margin-md-y {
  margin-top: 30px;
  margin-bottom: 30px;
}

.app-scope .common-margin-md-x {
  margin-left: 30px;
  margin-right: 30px;
}

.app-scope .common-margin-lg-y {
  margin-top: 45px;
  margin-bottom: 45px;
}

.app-scope .common-margin-lg-x {
  margin-left: 45px;
  margin-right: 45px;
}

// Error styles.
.card-error {
  position: relative;
  z-index: 1;
  margin-bottom: 20px;
  padding-left: 2.5rem;
}

.card-error::before {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 2em;
  height: 100%;
  color: $warn;
  font-family: 'Material Icons';
  font-size: 22px;
  content: "\e000";
}

.error-text {
  font-weight: normal;
  color: $warn;
}

// Debug output styles.
.debug-output {
  color: red;
  font-family: 'Courier New', Courier, monospace;
  font-weight: bold;
}

// Common info styles.
.card-info {
  margin: 2em 0;
  padding: 1em;
  color: #4b4b4b;
  background-color: #fffbe2;
  border: solid 1px #ffe294;
  border-radius: 4px;
}

.card-info p:last-of-type {
  margin-bottom: 0px;
}

// Common action styles.
.primary-actions-container {
  margin: 0;
}

.primary-actions {
  margin: 0;
}

.app-scope .primary-actions__btn {
  margin-right: 8px;
  margin-bottom: 15px;
  padding: 0 24px;
  font-size: 20px;
  line-height: 54px;
}

.app-scope .primary-actions__btn[matbadge] {
  margin-right: 16px;
}

.app-scope .primary-actions__btn:last-of-type {
  margin-right: 0px;
}

// Add larger icon for primary actions and variant for payment alternative icon.
.app-scope .primary-actions__btn .mat-icon {
  font-size: 1.5em;
  margin-top: -1px;
}

.app-scope .primary-actions__btn--pay .mat-icon {
  margin-left: 8px;
}

// Mat dialog styles.
.mat-dialog-default {
  min-width: 70vw;
  max-width: 70vw;
}

@media (min-width: 1200px)
{
  .mat-dialog-default {
    min-width: 45vw;
    max-width: 45vw;
  }
}

// Mat list styles.
.app-scope .mat-list-option:focus,
.app-scope .mat-list-option:hover {
  background-color: #e1f5fe;
}

.app-scope .mat-list-single-selected-option,
.app-scope .mat-list-single-selected-option:focus,
.app-scope .mat-list-single-selected-option:hover {
  background-color: $primary;
  color: #fff;
}

.app-scope .mat-list-single-selected-option .mat-list-icon {
  color: #fff;
}

// Mat slide toggle styles.
.app-scope .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #fff;
}

.app-scope .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(0, 0, 0, 0.54);
}

// Styles for Medical component dynamic html trigger question content.
.tq-item__question ul {
  list-style-type: disc;
}

// Styles for Quote component dynamic html description content.
.opt-info > p:last-of-type {
  margin-bottom: 0;
}

.opt-info .opt-info__html-desc ul {
  margin-bottom: 2em;
}

// Styles for Verisk no cover signposter options dynamic content.
.signpost-item__logo {
  margin-bottom: 30px;
}

.signpost-item__logo > img {
  height: 40px;
  width: auto;
}

@media (min-width: 992px) {
  .signpost-item__logo > img {
    height: 60px;
  }
}

.signpost-item__body {
  margin-bottom: 20px;
}

.signpost-item__contact {
  margin-top: 10px;
}

.signpost-item__contact > .contact-tel {
  font-weight: bold;
}

.signpost-item__contact > .contact-open,
.signpost-item__contact > .contact-link {
  margin-top: 12px;
  margin-bottom: 0;
}